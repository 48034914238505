import { createAction, props } from '@ngrx/store';
import { MdaReportReducerInitialState } from '../reducers/admin-mda-report.reducer';
import { QueryClient } from '@tanstack/angular-query-experimental';

const prefix = '[Admin-mda-report]';

export const setStateAction = createAction(
  `${prefix} set state`,
  props<Partial<MdaReportReducerInitialState>>()
);

export const setQueryAction = createAction(
  `${prefix} set query`,
  props<
    Partial<{
      maxDate: string;
      minDate: string;
      esId: string;
      pcId: string;
      pgId: string;
      tpcId: string;
      mdaId: string;
      toId: string;
      successAction?: (queryClient: QueryClient) => any;
    }>
  >()
);

export const resetQueryAction = createAction(
  `${prefix} reset query`,
  props<{ successAction?: (queryClient: QueryClient) => any }>()
);
