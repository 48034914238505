import { createAction, props } from '@ngrx/store';
import { MdaRevenueHeadReducerInitialState } from '../reducers/admin-mda-revenue-head.reducer';

const prefix = '[Admin-mda-revenue-head]';

export const setStateAction = createAction(
  `${prefix} set state`,
  props<Partial<MdaRevenueHeadReducerInitialState>>()
);

export const setQueryAction = createAction(
  `${prefix} set query`,
  props<
    Partial<{
      minDate: string;
      maxDate: string;
      esId: string;
    }>
  >()
);

export const resetQueryAction = createAction(
  `${prefix} reset query`,
  props<any>()
);
